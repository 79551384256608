import React, { useState } from "react";

const domains = [
  "www.imsmoking.xyz",
  "www.imsmoking.website",
  "www.imsmoking.store",
  "www.imsmoking.space",
  "www.imsmoking.online",
];

const isps = [
  "Telstra",
  "Optus",
  "Vodafone",
  "TPG",
  "Aussie Broadband",
  "Dodo",
  "iiNet",
  "Internode",
  "Primus",
  "Exetel",
  // Add other ISPs here
];

function App() {
  const [reported, setReported] = useState([]);
  const [isp, setISP] = useState('');
  const [selectedDomain, setSelectedDomain] = useState(domains[0]); // Default to the first domain

  const handleReport = () => {
    if (!isp) {
      alert("Please select your ISP.");
      return;
    }

    fetch("https://backend-lol.vercel.app/report", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ domain: selectedDomain, isp }),
    })
      .then((response) => response.json())
      .then((data) => {
        alert(`Reported ${selectedDomain} as not working with ${isp}. We will review it shortly.`);
        setReported([...reported, selectedDomain]);
      })
      .catch((error) => console.error("Error reporting domain:", error));
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center p-4">
      <header className="text-center my-8">
        {/* Logo adjusted with marginLeft */}
        <img 
          src="https://i.imgur.com/hB9DIys.png" 
          alt="Website Logo" 
          style={{ width: "150px", marginBottom: "20px", marginLeft: "40px" }} 
        />
        <h1 className="text-4xl font-bold">Proxy List</h1>
        <p className="text-gray-400 mt-2">These will be updated monthly.</p>
        <p className="text-gray-400">Last update: 04.09.2024</p>
      </header>

      <main className="flex flex-col items-center">
        <p className="mb-4 text-lg">
          Website Down? TGA blocked access? Try these mirror/proxy domain URLs:
        </p>
        <div className="space-y-4">
          {domains.map((domain) => (
            <div
              key={domain}
              className="flex items-center justify-between bg-gray-800 rounded-lg px-4 py-2 w-80 shadow-lg"
            >
              <a
                href={`https://${domain}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:underline"
              >
                {domain}
              </a>
              <button
                onClick={() => setSelectedDomain(domain)}
                className="bg-red-500 hover:bg-red-600 text-white font-semibold py-1 px-2 rounded-lg"
                disabled={reported.includes(domain)}
              >
                {reported.includes(domain) ? "Reported" : "Report"}
              </button>
            </div>
          ))}
        </div>

        <div className="mt-8 w-full flex flex-col items-center">
          <h2 className="text-lg font-semibold mb-4">Having Issues?</h2>
          <label className="text-sm mb-2">Select the domain that's having issues:</label>
          <select
            className="mb-4 p-2 rounded border border-gray-600 bg-gray-800 text-white"
            value={selectedDomain}
            onChange={(e) => setSelectedDomain(e.target.value)}
          >
            {domains.map((domain) => (
              <option key={domain} value={domain}>{domain}</option>
            ))}
          </select>
          
          <label className="text-sm mb-2">Select your ISP:</label>
          <select
            className="mb-4 p-2 rounded border border-gray-600 bg-gray-800 text-white"
            value={isp}
            onChange={(e) => setISP(e.target.value)}
          >
            <option value="">Select your ISP</option>
            {isps.map((isp) => (
              <option key={isp} value={isp}>{isp}</option>
            ))}
          </select>

          <button
            onClick={handleReport}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg"
          >
            Submit
          </button>
        </div>
      </main>

      <footer className="mt-8 text-sm text-gray-600">
        © 2024 ImSmoking. All rights reserved.
      </footer>
    </div>
  );
}

export default App;
